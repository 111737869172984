import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { styles, colors, components } from 'herald-fe-shared';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Page from '~/layout/LayoutMarketing';
import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import UserstandLogo from '~/components/blog/UserstandLogo';
import FeaturedPost from '~/components/blog/FeaturedPost';
import SubscribeSection, {
  SubscribeInput,
} from '~/components/blog/SubscribeSection';

const Styled = styled.div`
  .blog-post--opener {
    margin-top: -4rem;
    padding-top: 8rem;
    padding-bottom: 5rem;
    background: ${colors.PURPLE_DARK(0.05)};
    margin-bottom: 5rem;
  }

  .blog-post__content {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 20rem auto;
    margin-bottom: 5rem;
  }

  .blog-post__opener__featured .post {
    min-height: 24rem;
  }
  .blog-post__opener__featured .post:hover {
    transform: none;
  }

  .blog-post__content__sidebar {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .blog-post__content__sidebar__company {
    padding: 2rem;
    background: ${colors.PURPLE_DARK(0.05)};
    margin: 2rem 0;
  }
  .blog-post__content__sidebar__company__description p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .blog-post__content__sidebar__coming-soon {
    padding: 2rem;
    background: ${colors.PURPLE_LIGHT(0.05)};
    margin-bottom: 2rem;
    transition: 250ms all;
  }
  .coming-soon--fixed {
    position: fixed;
    bottom: 2rem;
    left: 2rem;
  }
  .coming-soon--fixed__container {
    padding: 2rem;
    background: ${colors.PURPLE_LIGHT(0.05)};
    width: 20rem;
  }
  .coming-soon--fixed .input__container,
  .coming-soon--fixed button {
    height: 2.5rem;
  }
  .coming-soon--fixed button h5,
  .coming-soon--fixed input {
    font-size: 0.9rem !important;
  }
  .coming-soon__subscribe {
    margin: -2rem;
    padding: 2rem;
    padding-bottom: 3rem;
    margin-top: 2rem;
    border-top: 1px solid ${colors.PURPLE_LIGHT(0.2)};
  }
  .coming-soon__subscribe p {
    text-align: center;
    margin-bottom: 1rem;
  }
  .coming-soon__subscribe .help {
    width: 16rem;
    top: calc(100% + 1rem);
  }
  .coming-soon--mobile {
    display: none;
    padding: 2rem;
    background: ${colors.PURPLE_DARK(0.05)};
    border-bottom: ${styles.BORDER};
  }
  .coming-soon {
    margin-top: 1.5rem;
  }
  .coming-soon__title {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  .coming-soon__subtitle {
    font-size: 0.8rem;
  }
  .other-posts {
    grid-column: 1 / 3;
  }
  .other-posts__grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
  .other-posts__grid .post__text__title {
    font-size: 1.2rem;
  }
  .other-posts__grid .post__text__subtitle {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 800px) {
    .blog-post--opener {
      padding-bottom: 0;
      padding-top: 4rem;
      margin-bottom: 3rem;
      overflow: visible;
    }
    .blog-post__opener {
      flex-flow: column;
    }
    .blog-post__opener__featured {
      margin-top: 0;
      width: 100vw;
      margin-left: -2rem;
    }
    .blog-post__opener__featured .post {
      border-radius: 0;
    }
    .blog-post__content {
      grid-template-columns: 1fr;
      grid-gap: 3rem;
      margin-bottom: 2rem;
    }
    .blog-post__content__sidebar {
      display: none;
    }
    .blog-post__content__sidebar__company label {
      font-size: 1rem;
    }
    .blog-post__content__sidebar__company__description p {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
    .blog-post__content__sidebar__coming-soon,
    .coming-soon--fixed {
      display: none;
    }
    .coming-soon--mobile {
      display: block;
    }
    .other-posts {
      margin-left: -2rem;
    }
    .other-posts__grid {
      grid-template-columns: 1fr;
    }
    .other-posts__grid .post__image {
      display: none;
    }
    .other-posts__grid .post__text {
      padding: 1rem 2rem;
    }
  }
  @media screen and (max-width: 500px) {
    .herald-promo {
      flex-flow: column;
      align-items: flex-start;
    }
    .herald-promo__right {
      margin-top: 1rem;
    }
  }
`;

const StyledContent = styled.div`
  p {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;
    font-family: adobe-caslon-pro, Georgia, 'Times New Roman', Times, serif;
    color: ${colors.GRAY_4()};
  }
  a {
    font-family: adobe-caslon-pro, Georgia, 'Times New Roman', Times, serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 1rem 0;
    color: ${colors.GRAY_4()};
  }
  h1 {
    font-size: 2rem;
    line-height: 2.2rem;
    margin-top: 3rem;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  h3 {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  h4 {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
  h5 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  ul {
    display: initial;
  }
  li {
    list-style-type: disc;
    margin-left: 1rem;
  }
  li p {
    margin-bottom: 0.5rem;
  }
  blockquote {
    margin: 3rem 0;
    border-top: 1px solid ${colors.PURPLE_DARK()};
    border-bottom: 1px solid ${colors.PURPLE_DARK()};
    background: ${colors.PURPLE_DARK(0.02)};
    padding: 2rem;
  }
  blockquote p {
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: ${colors.PURPLE_DARK()};
    margin: 0;
    text-align: center;
    font-style: italic;
  }
  figure {
    margin: 2rem 0;
    width: 100%;
    background: ${colors.GRAY_2(0.5)};
    padding: 2rem;
    display: flex;
    flex-flow: column;
    border-radius: ${styles.BORDER_RADIUS};
  }
  figure img {
    width: 100%;
    margin-bottom: 2rem;
    box-shadow: ${styles.BOX_SHADOW};
  }
  figure figcaption {
    width: 100%;
    color: ${colors.GRAY_3()};
    font-size: 1rem;
    text-align: center;
  }
  .herald-promo {
    padding: 2rem;
    margin: 3rem 0;
    width: 100%;
    background: ${colors.PURPLE_LIGHT(0.1)};
    border-left: 3px solid ${colors.PURPLE_LIGHT()};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .herald-promo--subscribe {
    flex-flow: column;
  }
  .herald-promo--subscribe .herald-promo__right {
    margin-top: 1rem;
    width: 100%;
  }
  .herald-promo__left {
    margin-right: 2rem;
  }
  .herald-promo__title {
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin: 0;
    color: ${colors.PURPLE_LIGHT()};
  }
  .herald-promo__text {
    color: ${colors.GRAY_3()};
    line-height: 1.4rem;
    margin-top: 0.5rem;
  }
  .herald-promo h5 {
    color: ${colors.WHITE()};
    margin: 0;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 800px) {
    h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
      line-height: 2rem;
    }
    h4,
    h5 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    blockquote p {
      font-size: 2rem;
      line-height: 3rem;
    }
    p,
    li {
      font-size: 1.75rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
    li {
      margin-bottom: 0rem;
      margin-left: 2rem;
    }
    figure {
      margin: 2rem -2rem;
      width: 100vw;
      padding: 2rem 1rem;
    }
    .herald-promo {
      align-items: flex-start;
    }
    .herald-promo__left {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`;

const ComingSoon: React.FC<{ post: IComingSoon }> = (props) => {
  const { post } = props;
  return (
    <Styled className="coming-soon">
      <h5 className="coming-soon__title">{post.title}</h5>
      <p className="coming-soon__subtitle text-no-margin text-small">
        {post.subtitle}. Coming {post.postedAt}.
      </p>
    </Styled>
  );
};

interface IInterviewQuery {
  contentfulInterview: IInterview;
  allContentfulComingSoon: {
    nodes: IComingSoon[];
  };
  allContentfulInterview: { nodes: IInterview[] };
}

const SCROLL_POINT = 1500;
const renderOptions = {
  renderNode: {
    'embedded-asset-block': (node: any) => {
      let obj = null;
      if (node.data.target.fields) {
        obj = node.data.target.fields;
      }
      return obj ? (
        <figure>
          <a href={obj.file['en-US'].url} target="_blank">
            <img src={obj.file['en-US'].url} />
          </a>
          <figcaption>{obj.description['en-US']}</figcaption>
        </figure>
      ) : null;
    },
    'embedded-entry-block': (node: any) => {
      if (node.data.target.sys.contentType.sys.id === 'promo') {
        const subscribe = node.data.target.fields.subscribe
          ? node.data.target.fields.subscribe['en-US']
          : false;
        const ctaText = node.data.target.fields.cta
          ? node.data.target.fields.cta['en-US']
          : 'Learn More';

        let cta = (
          <Link to="/">
            <components.Button large={true}>{ctaText}</components.Button>
          </Link>
        );
        if (node.data.target.fields.url) {
          cta = (
            <a href={node.data.target.fields.url['en-US']}>
              <components.Button large={true}>{ctaText}</components.Button>
            </a>
          );
        }
        if (subscribe) {
          cta = <SubscribeInput />;
        }
        const title =
          node.data.target.fields.title['en-US'] ||
          'Start prioritizing your product roadmap to serve customer needs.';
        const description = node.data.target.fields.description
          ? node.data.target.fields.description['en-US']
          : 'Herald helps product managers align their team around customer needs.';
        return (
          <div
            className={`herald-promo herald-promo--${
              subscribe ? 'subscribe' : 'cta'
            }`}
          >
            <div className="herald-promo__left">
              <h3 className="herald-promo__title">{title}</h3>
              <div className="herald-promo__text">{description}</div>
            </div>
            <div className="herald-promo__right">{cta}</div>
          </div>
        );
      }
      return null;
    },
  },
};

const Interview: React.FC<{ data: IInterviewQuery }> = (props) => {
  const interview = props.data.contentfulInterview;
  const soon = props.data.allContentfulComingSoon.nodes;
  const others = props.data.allContentfulInterview.nodes;

  const [scrolled, setScrolled] = useState(false);

  const { title, summary, user } = interview;
  const featuredImage = useMemo(() => {
    if (interview.featuredImage) {
      return `https:${interview.featuredImage.file.url}`;
    }
    return `https:${interview.user.photo.file.url}`;
  }, [interview]);

  useEffect(() => {
    const onScroll = () => {
      const y = window.scrollY;
      const SCROLL_MAX =
        document.documentElement.scrollHeight - window.innerHeight - 1300;
      if (y > SCROLL_POINT && y < SCROLL_MAX && !scrolled) {
        setScrolled(true);
      } else if (y < SCROLL_POINT && scrolled) {
        setScrolled(false);
      } else if (y > SCROLL_MAX && scrolled) {
        setScrolled(false);
      }
    };
    window.addEventListener('wheel', onScroll, {
      capture: false,
      passive: true,
    });
    return () => window.removeEventListener('wheel', onScroll);
  }, [scrolled]);

  return (
    <Page
      title={title}
      image={featuredImage}
      description={
        summary ? summary.summary : 'A post on Userstand, a blog by Herald.'
      }
    >
      <Styled>
        <Section className="blog-post--opener">
          <Container className="container blog-post__opener">
            <div className="blog-post__opener__featured">
              <FeaturedPost interview={interview} />
            </div>
          </Container>
        </Section>

        <Container className="blog-post__content">
          <div className="blog-post__content__sidebar">
            <UserstandLogo small={true} />
            <div className="blog-post__content__sidebar__company">
              <label>Company</label>
              <div className="blog-post__content__sidebar__company__description">
                {user.company.summary && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: user.company.summary.childMarkdownRemark.html,
                    }}
                  />
                )}
                <p>
                  <a target="_blank" href={user.company.website}>
                    {user.company.website}
                  </a>
                </p>
              </div>
            </div>
            {/*<div className={`blog-post__content__sidebar__coming-soon`}>
              <label>Coming Soon</label>
              <div className="blog-post__content__sidebar__coming-soon__posts">
                {soon.map((comingSoon) => (
                  <ComingSoon key={comingSoon.id} post={comingSoon} />
                ))}
              </div>
                </div>*/}
          </div>

          {/*<div className={`coming-soon--fixed`}>
            <components.FadeIn visible={scrolled}>
              <div className="coming-soon--fixed__container">
                <label>Coming Soon</label>
                <div className="blog-post__content__sidebar__coming-soon__posts">
                  {soon.map((comingSoon) => (
                    <ComingSoon key={comingSoon.id} post={comingSoon} />
                  ))}
                </div>
                <div className="coming-soon__subscribe">
                  <p className="text-small">
                    Stay on top of what the most innovative companies are doing
                    to understand their users.
                  </p>
                  <SubscribeInput />
                </div>
              </div>
            </components.FadeIn>
          </div>*/}

          <StyledContent className="blog-post__content__main">
            {documentToReactComponents(interview.body.json, renderOptions)}
          </StyledContent>

          <div className="other-posts">
            <h4 style={{ textAlign: 'center' }}>
              <label>Other Posts</label>
            </h4>
            <div className="other-posts__grid">
              {others.map((i) => (
                <Link key={i.slug} to={`/userstand/${i.slug}`}>
                  <FeaturedPost interview={i} secondary={true} />
                </Link>
              ))}
            </div>
          </div>
        </Container>

        {/*<div className="coming-soon--mobile">
          <label>Coming Soon</label>
          <div className="blog-post__content__sidebar__coming-soon__posts">
            {soon.map((comingSoon) => (
              <ComingSoon key={comingSoon.id} post={comingSoon} />
            ))}
          </div>
            </div> */}

        <SubscribeSection />
      </Styled>
    </Page>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulInterview(id: { eq: $id }) {
      title
      createdAt(formatString: "MMMM YYYY")
      postedAt(formatString: "MMMM D, YYYY")
      featuredImage {
        file {
          url
        }
      }
      author {
        name
      }
      summary {
        summary
      }
      body {
        json
      }
      user {
        name
        title
        photo {
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
          }
          file {
            url
          }
        }
        company {
          name
          website
          summary {
            childMarkdownRemark {
              html
            }
          }
          logo {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
        }
      }
    }
    allContentfulInterview(
      sort: { order: DESC, fields: postedAt }
      limit: 4
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        createdAt(formatString: "MMMM D, YYYY")
        postedAt(formatString: "MMMM D, YYYY")
        slug
        featured
        author {
          name
        }
        summary {
          summary
        }
        body {
          json
        }
        user {
          name
          title
          photo {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          company {
            name
            website
            summary {
              summary
            }
            logo {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
              }
            }
          }
        }
      }
    }
    allContentfulComingSoon(limit: 3, sort: { fields: postedAt, order: ASC }) {
      nodes {
        id
        title
        subtitle
        postedAt(formatString: "MMMM D, YYYY")
      }
    }
  }
`;

export default Interview;
