import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors } from 'herald-fe-shared';

interface IUserstandLogoProps {
  small?: boolean;
}

const Styled = styled.div`
  width: fit-content;
  text-align: center;
  .userstand__logo {
    text-transform: uppercase;
    letter-spacing: 2px;
    width: fit-content;
    border: 5px solid ${colors.PURPLE_DARK()};
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 1rem;
  }
  .userstand__subhead {
    color: ${colors.PURPLE_DARK()};
    font-size: 0.8rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: center;
  }
  &.small .userstand__subhead {
    font-size: 0.7rem;
    line-height: 1.3rem;
  }
`;

const UserstandLogo: React.FC<IUserstandLogoProps> = (props) => {
  const { small } = props;
  return (
    <Link to="/userstand">
      <Styled className={small ? 'small' : 'normal'}>
        {small ? (
          <h4 className="userstand__logo">Userstand</h4>
        ) : (
          <h2 className="userstand__logo">Userstand</h2>
        )}
        <p className="userstand__subhead text-caps">
          how customer-obsessed companies
          <br />
          <strong>understand their users</strong>
        </p>
      </Styled>
    </Link>
  );
};

export default UserstandLogo;
