import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';

import { styles, colors } from 'herald-fe-shared';

export interface IFeaturedPostProps {
  interview: IInterview;
  secondary?: boolean;
}

const Styled = styled.div`
  background: ${colors.WHITE()};
  box-shadow: ${styles.BOX_SHADOW};
  display: flex;
  border-radius: ${styles.BORDER_RADIUS};
  overflow: hidden;
  margin: auto;
  transition: 250ms all;
  height: 100%;

  max-height: 30rem;
  &:hover {
    transform: translateY(-10px);
  }
  .post__image {
    flex: 0 0 35%;
    overflow: hidden;
  }
  .post__image .gatsby-image-wrapper {
    min-height: 100%;
    min-width: 100%;
  }
  .post__text {
    padding: 2rem;
  }
  .post__text__logo {
    width: 5rem;
    margin-bottom: 1rem;
  }
  .post__text__title {
    color: ${colors.BLUE_DARK()};
    margin-bottom: 0.5rem;
  }
  .post__text__subtitle {
    color: ${colors.GRAY_3()};
    line-height: 1.2rem;
  }
  .post__text__date,
  .post__text__byline {
    margin: 0;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
  .post__text__date {
    margin-top: 0;
  }
  &.post--secondary .post__text__title {
    font-size: 1.5rem;
  }
  &.post--secondary .post__text__subtitle {
    font-size: 1rem;
  }
  @media screen and (max-width: 500px) {
    flex-flow: column;
    max-height: 50rem;

    &.post--secondary {
      max-height: none;
    }

    .post__image .gatsby-image-wrapper {
      margin-top: -15%;
    }

    .post__image {
      flex: 0 0 18rem;
    }
  }
`;

const FeaturedPost: React.FC<IFeaturedPostProps> = (props) => {
  const { secondary, interview } = props;
  return (
    <Styled
      className={`post post--feature post--${
        secondary ? 'secondary' : 'primary'
      }`}
    >
      <div className="post__image">
        <Image
          imgStyle={{ objectFit: 'cover' }}
          fluid={interview.user.photo.fluid}
        />
      </div>
      <div className="post__text">
        <div className="post__text__logo">
          <Image fluid={interview.user.company.logo.fluid} />
        </div>
        <h3 className="post__text__title">{interview.title}</h3>
        <p className="post__text__subtitle text-no-margin">
          <strong>
            With {interview.user.name}, {interview.user.title}
          </strong>
        </p>
        <p className="post__text__byline text-no-margin text-gray">
          {interview.author && (
            <>
              By <Link to="/about">{interview.author[0].name}</Link>
              {interview.author[1] && (
                <>
                  {' '}
                  and <Link to="/about">{interview.author[1].name}</Link>
                </>
              )}
            </>
          )}
        </p>
        {/* <p className="post__text__date text-no-margin text-gray">
          Posted {interview.postedAt}
        </p> */}
      </div>
    </Styled>
  );
};

export default FeaturedPost;
