import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { colors, components } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';

const Styled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .subscribe__text {
    margin-right: 2rem;
  }

  .subscribe__text__title {
    color: ${colors.GRAY_4()};
    margin-bottom: 0.5rem;
  }
  .subscribe__text__description {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  @media screen and (max-width: 500px) {
    flex-flow: column;
    .subscribe__text {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`;

const StyledSubscribeInput = styled.div`
  width: 100%;
  display: flex;
  .email-input__input {
    margin-right: 1rem;
    flex: 1 1 auto;
  }
  &.small {
    flex-flow: column;
    .email-input__input {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
    button {
      width: fit-content;
    }
  }
  @media screen and (max-width: 500px) {
    flex-flow: column;
    .email-input__input {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
    }
    button {
      width: fit-content;
    }
  }
`;

const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const validateEmail = (email: string) =>
  emailRegex.exec(email) ? true : false;

const subscribeEmail = (email?: string) => {
  if (email) {
    const userId = email.toLowerCase();
    return fetch(
      `${process.env.GATSBY_API_BASE_URL}/marketing/request-subscription`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userId }),
      }
    );
  }
  return Promise.reject();
};

export const SubscribeInput: React.FC<any> = (props) => {
  const [email, setEmail] = useState('');
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const subscribe = useCallback(() => {
    setValidation(true);
    if (validateEmail(email)) {
      setLoading(true);
      subscribeEmail(email).then(() => {
        setLoading(false);
        setSubscribed(true);
      });
    }
  }, [email]);

  const message = useMemo(() => {
    if (validation && !validateEmail(email)) {
      return `Whoops! We need a valid email address.`;
    }
    if (subscribed) {
      return `🚀 You've been subcribed to the Userstand blog!`;
    }
  }, [validation, subscribed]);

  const status = useMemo(() => {
    if (subscribed) {
      return 'default';
    }
    if (validation && !validateEmail(email)) {
      return 'error';
    }
    return 'default';
  }, [validation, subscribed, email]);

  return (
    <StyledSubscribeInput {...props}>
      <components.Input
        className="email-input__input"
        large={true}
        value={email}
        status={status}
        message={message}
        onChange={(e) => setEmail(e.target.value)}
        inputProps={{
          placeholder: 'Enter your email',
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              subscribe();
            }
          },
        }}
      />
      <components.Button loading={loading} onClick={subscribe} large={true}>
        Subscribe
      </components.Button>
    </StyledSubscribeInput>
  );
};

const SubscribeSection: React.FC = () => {
  return (
    <Section
      style={{ background: colors.PURPLE_DARK(0.05), padding: '4rem 0' }}
    >
      <Container>
        <Styled>
          <div className="subscribe__text">
            <h4 className="subscribe__text__title">Subscribe to Userstand</h4>
            <p className="subscribe__text__description text-no-margin">
              Stay on top of what the most customer-obsessed companies are doing
              to understand their users. We publish 2-4 articles every month.
            </p>
          </div>
          <SubscribeInput />
        </Styled>
      </Container>
    </Section>
  );
};

export default SubscribeSection;
